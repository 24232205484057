import React, { useState } from "react";
import "./DateCalender.css";
import { Calendar } from "react-multi-date-picker";
import { CalenderLangData } from "../../../../../../constant/CalenderLangData";
import { getLocalStorage } from "../../../../../shared/local_storage";
import { useTranslation } from "react-i18next";
import { Config, KEYS } from "../../../../../../constant/Index";
const DateCalender = ({
  activeFilter,
  setSearchFields,
  date,
  setActiveFilter,
  inputFiled,
  setInquiryFirstData,
  closePopup,
}) => {
 
  const { t } = useTranslation();

  const handleDateChange = (value) => {
    if (inputFiled) {
      setInquiryFirstData((prevSearchFields) => ({
        ...prevSearchFields,
        date: value,
        from_date: value[0]
          ? formatDate(new Date(value[0]).toISOString()?.split("T")[0])
          : undefined,
        to_date: value[1]
          ? formatDate(new Date(value[1]).toISOString()?.split("T")[0])
          : undefined,
      }));
    } else {
      setSearchFields((prevSearchFields) => ({
        ...prevSearchFields,
        date: value,
        from_date: value[0]
          ? formatDate(new Date(value[0]).toISOString()?.split("T")[0])
          : undefined,
        to_date: value[1]
          ? formatDate(new Date(value[1]).toISOString()?.split("T")[0])
          : undefined,
      }));
    }
    if (value && value.length === 2) {
      if (closePopup) {
        closePopup();
      }
      if(setActiveFilter){
        setActiveFilter("");
      }
    }
  };

  function formatDate(inputDate) {
    if (!inputDate) {
      return t("search_filter.invalid_date");
    }
    const dateObject = new Date(inputDate);
    if (isNaN(dateObject.getTime())) {
      return t("search_filter.invalid_date");
    }
    const options = { weekday: "short", day: "numeric", month: "short" };
    const formattedDate = dateObject.toLocaleDateString("en", options);
    return formattedDate;
  };

  return (
    <div
      className={`
        ${inputFiled == "date" ? "heroCalender__drop" : "inputCalender__drop"}
      `}
    >
      {activeFilter == "date" || inputFiled == "date" ? (
        <Calendar
          value={date}
          format={"YYYY/MM/DD"}
          onChange={handleDateChange}
          weekStartDayIndex={1} 
          range
          locale={CalenderLangData?.[getLocalStorage(KEYS.lang)]}
          numberOfMonths={2}
          showOtherDays
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default DateCalender;
