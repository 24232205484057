import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { KEYS, LANGUAGES_CODE } from "../constant/Index";
import { getLocalStorage } from "../components/shared/local_storage";
import { getLanguageFromDomain } from "../components/shared/top-level-domain";

const Languages = ["da","en", "de", "no", "sv"];
const selectedLanguage = getLocalStorage(KEYS.lang) ?? ( process.env.REACT_APP_DEVELOPMENT ? LANGUAGES_CODE.Danish : getLanguageFromDomain());

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: LANGUAGES_CODE.English,
    lng: selectedLanguage,
    debug: true,
    whitelist: Languages,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;