import React from 'react'

const MinusOutline = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="2" viewBox="0 0 18 2" fill="none">
            <path d="M17 1L0.999999 1" stroke="#666666" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default MinusOutline