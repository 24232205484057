import Lottie from "react-lottie";
import BullLoaderAnimation from '../../animations/bull-loader.json';

const BullLoader =({loaderStyle})=> {
    const mergeStyles = { height: "300px", width: "300px", ...loaderStyle };
    return (
        <Lottie options={{ animationData: BullLoaderAnimation }} style={mergeStyles} />
    )
};

export { BullLoader }