// GuestsPopup.jsx
import React, { useState } from "react";
import PlusOutline from "../../../../../../assets/Icons/plus__outline";
import MinusOutline from "../../../../../../assets/Icons/minus__outline";
import "./GuestsPopup.css";
const GuestsPopup = ({
  activeFilter,
  onClose,
  searchFields,
  setSearchFields,
  values,
}) => {
  const handleGuestsPopup = (value) => {
    setSearchFields({
      ...searchFields,
      guests: value,
    });
  };
  const handleIncrement = () => {
    handleGuestsPopup(values + 1);
  };

  const handleDecrement = () => {
    if (values > 8) {
      handleGuestsPopup(values - 1);
    }
  };

  const handleDone = () => {
    onClose();
  };

  const resetPopup = () => {
    handleGuestsPopup(8);
    onClose();
  };

  return (
    <div
      className={`search__popup min__w__230 ${activeFilter === "guests" ? "active__popup" : ""
        }`}
    >
      <div className="counter">
        <div className="add cursor__p" onClick={handleDecrement}>
          <MinusOutline />
        </div>
        <span className="number" style={{userSelect: 'none'}}>{values}</span>
        <div className="add cursor__p" onClick={handleIncrement}>
          <PlusOutline />
        </div>
      </div>
      <div className="btn__container">
        <span className="poppins__16 cursor__p" onClick={resetPopup} style={{userSelect: 'none'}}>
          Reset
        </span>
        <button className="poppins__16 cursor__p btn" onClick={handleDone} style={{userSelect: 'none'}}>
          Done
        </button>
      </div>
    </div>
  );
};

export default GuestsPopup;
