"use client";
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { yakSportsApi } from './storeApis'
import LoaderReducer from './LoaderReducer'
import SettingsReducer from './SettingsReducer'
import SnackBarReducer from './SnackBarReducer';
const appReducer = combineReducers({
  LoaderReducer,
  SettingsReducer,
  SnackBarReducer,
  [yakSportsApi.reducerPath]: yakSportsApi.reducer,
});
const rootReducer = (state, action) => {
  if (action.type == 'logout') {
    // console.log('logged out from store')
    state = undefined;
  }
  return appReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(yakSportsApi.middleware),
})
setupListeners(store.dispatch)