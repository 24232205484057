import React from 'react'

const GolfBlue = ({className, iconColor = "#4A6DA7"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
            <path d="M5.47095 16.263L10 19.9742C9.61972 20.3637 9.25498 20.7646 8.7178 20.9214C8.44861 21 8.15975 21 7.58202 21H4.64577C3.12431 21 1.69593 20.514 1.15891 18.9348C0.654472 17.4514 1.35188 14.9891 3.23816 15C3.93452 15.0041 4.44666 15.4237 5.47095 16.263Z" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10 20L14.5 9" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.6206 2.42288L14.1333 8.00001C14.0557 8.29111 14.1934 8.59668 14.4628 8.73142C14.7642 8.8821 15.1308 8.77111 15.298 8.47856L18.1617 3.46705C18.5954 2.70809 18.2263 1.7421 17.397 1.46568C16.6397 1.21323 15.8263 1.65152 15.6206 2.42288Z" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default GolfBlue