import React, { useEffect } from "react";
import YAKText from "../../../../../shared/YakText/YAKText";
import SportIcon from "../../../../../shared/SportIcon/SportIcon";
import useHomePageDataManager from "../../../../../../hooks/useHomePageDataManager";
import DZText from "../../../../../text/DZText";
const CategoryPopup = ({
  activeFilter,
  setSearchFields,
  searchFields,
  handleClosePopup,
  inputFiled,
  setInquiryFirstData,
  closePopup,
}) => {
  const { sports } = useHomePageDataManager();
  const handleCategory = (id, name) => {
    console.log("handleCategory", id, name);
    if (inputFiled) {
      setInquiryFirstData((prevSearchFields) => ({
        ...prevSearchFields,
        sportId: id,
        sport: name,
      }));
    } else {
      setSearchFields({ ...searchFields, sportId: id, sport: name });
      handleClosePopup();
    }
    if (closePopup) {
      closePopup();
    }
  };
  // console.log(sports, "sports");

  return (
    <div
      className={`search__popup min__w__200 ${
        inputFiled == "category" ? "" : ""
      }  ${
        activeFilter === "category" || inputFiled == "category"
          ? "active__popup"
          : ""
      }`}
    >
      {sports?.map((sport, index) => (
        <div
          className="popup__text cursor__p"
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            handleCategory(sport?.id, sport?.name);
          }}
        >
          <span className="poppins__18 ">
            <SportIcon sport={sport?.name?.en} iconColor="#4A6DA7" />
            <DZText>{sport?.name}</DZText>
          </span>
        </div>
      ))}
    </div>
  );
};

export default CategoryPopup;
