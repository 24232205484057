import { createSlice } from "@reduxjs/toolkit";
export const slice = createSlice({
  name: "SnackBarReducer",
  initialState: {
    message: null,
    error: false,
  },
  reducers: {
    setSnackBarMessage: (state, action) => {
      state.message = action.payload;
    },
    setErrorTrue: (state) => {
      state.error = true;
    },
    hideSnackBarMessage: (state) => {
      state.message = null;
      state.error = false;
    },
  },
});
export const { setSnackBarMessage, hideSnackBarMessage, setErrorTrue } = slice.actions;
export const snackBarMessageSelector = (state) => state.SnackBarReducer.message;
export const errorSelector = (state) => state.SnackBarReducer.error;
export default slice.reducer; 