import React from 'react'

const FootballBlue = ({className, iconColor = "#4A6DA7"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" className={className}>
            <path d="M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke={iconColor} stroke-width="1.5" />
            <path d="M10.7077 8.34893C10.882 8.21702 11.118 8.21702 11.2923 8.34893L13.545 10.054C13.7193 10.1859 13.7922 10.4197 13.7256 10.6332L12.8652 13.3921C12.7986 13.6055 12.6077 13.75 12.3923 13.75H9.60772C9.39232 13.75 9.20141 13.6055 9.13484 13.3921L8.27437 10.6332C8.20781 10.4197 8.28073 10.1859 8.45499 10.054L10.7077 8.34893Z" stroke={iconColor} stroke-width="1.5" />
            <path d="M11 8V4M14 10L18 8.5M13 14L15 17M9 13.5L7 16M8 10.5L4 9.5" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 1.5L11.0165 3.62241L14 1.5M1 11.7998L4.19655 9.43885L2.55548 5.72045M18.4703 17.8531L14.6158 17.1555L13.2655 21M19.0298 5.19586L17.8035 8.38978L21 10.7507M7.00992 20.4059L7.05142 16.1665L3.00331 16.21" stroke={iconColor} stroke-width="1.5" stroke-linejoin="round" />
        </svg>
    )
}

export default FootballBlue;