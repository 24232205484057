import React from 'react'

const SwimmingBlue = ({className, iconColor = "#4A6DA7"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" fill="none">
            <path d="M9.71842 3.86177L14 13C11.8333 13 11.1739 11.8571 8.91304 10.7143C7.10435 9.8 4.57971 9.95238 3.82609 10.1429L6.85739 7.99977C7.16585 7.78169 7.32008 7.67265 7.36937 7.50667C7.41866 7.34068 7.34893 7.16514 7.20947 6.81406L6.78519 5.74593C6.62654 5.34653 6.54721 5.14683 6.40384 5.00197C6.35388 4.9515 6.2987 4.9065 6.23922 4.86772C6.06848 4.7564 5.8569 4.71887 5.43376 4.64379L2.18315 4.06706C1.4987 3.94562 1 3.35065 1 2.65551C1 1.78222 1.77418 1.11181 2.63851 1.23665L7.0343 1.87152C7.82604 1.98586 8.22191 2.04304 8.5521 2.23177C8.68974 2.31045 8.81754 2.40521 8.93281 2.51407C9.20932 2.7752 9.37902 3.13739 9.71842 3.86177Z" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <circle cx="18" cy="7" r="3" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1 15.0843C2.05556 11.5527 6.7685 13.1736 10.5 15.0843C14.2315 16.995 18 18.2108 20 15.0843" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default SwimmingBlue;