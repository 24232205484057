import React, { useState } from 'react'
import LockBlack from '../../assets/Icons/lock__black'
import { useUpdatePasswordMutation } from '../../redux/storeApis'
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackBarManager } from '../../hooks/useSnackBarManager';
import RoutesLink from '../../constant/RoutesLink';
import Input from '../../components/shared/input/Input';
import { useTranslation } from 'react-i18next';

const SetPasswordPage = () => {

    const { slug } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { fnShowSnackBar } = useSnackBarManager();
    const [updatePassword, { isLoading: isLoadingUpdatePassword } ] = useUpdatePasswordMutation();

    const [ inputs, setInputs ] = useState({ password : "", confirm_password : "" });

    const fnUpdatePassword =()=>{
        const validation = inputs?.password && inputs?.confirm_password && inputs?.password == inputs?.confirm_password;
        if(validation) {
            const body = { slug, password : inputs?.password };
            updatePassword(body).unwrap().then((payload)=>{
                if(payload?.success) {
                    fnShowSnackBar('password updated successfully, please login to continue!');
                    navigate(RoutesLink.HOME);
                    setInputs(null);
                }
            }).catch(()=>{
                fnShowSnackBar('some error occurred please try again!')
            })
        } else {
            fnShowSnackBar('password does not match!');
        }
    };

  return (
    <div class="password__modal flex max-w-[450px] flex-col items-center justify-center gap-[24px] rounded-[16px] p-[24px] px-[32px]">
        <div class="flex flex-col items-center gap-[8px]">
            <h3 class="text-center text-[28px] font-[500] text-[#333]">New Password</h3>
            <p class="max-w-[290px] text-center text-[16px] text-[#666]">Create Your Password</p>
        </div>
        <div class="flex w-full flex-col gap-[16px]">
            <Input
              icon={<LockBlack className="w__24 h__24" />}
              value={inputs?.password}
              placeholder={'New Password'}
              onChange={(e)=> setInputs({...inputs, password : e.target.value})}
              type="password"
              name="password"
              autoComplete="new-password"
            />
            <Input
              icon={<LockBlack className="w__24 h__24" />}
              value={inputs?.confirm_password}
              placeholder={'Confirm Password'}
              onChange={(e)=> setInputs({...inputs, confirm_password : e.target.value})}
              type="password"
              name="confirm_password"
              autoComplete="new-password"
            />
        </div>
        <button onClick={fnUpdatePassword} disabled={isLoadingUpdatePassword} class="w-full rounded-[100px] bg-[#4A6DA7] px-[24px] py-[14px] text-[#FFF] transition-all hover:scale-95">
            { isLoadingUpdatePassword ? 'Loading...' : t('inquiry_model.submit')}
        </button>
    </div>
  )
}

export default SetPasswordPage