import { useEffect } from "react";

export default function ({ parentClassName, onClickClassName }) {

    useEffect(()=>{ fnScrollToTop()},[window.location.pathname]);

    useEffect(() => {
        const button = document.querySelector(`.${onClickClassName}`);
        if (button) { button.addEventListener('click', fnScrollToTop); };
        
        return () => {
            if (button) { button.removeEventListener('click', fnScrollToTop); }
        };
    }, []);

    const fnScrollToTop = () => {
      let scrollParent = document.querySelector(`.${parentClassName}`);
      while (scrollParent && scrollParent !== document.body) {
          if (scrollParent.scrollHeight > scrollParent.clientHeight) {
              scrollParent.scrollTo(0,0);
              break;
          }
          scrollParent = scrollParent.parentElement;
      }
      if (scrollParent === document.body) {
          window.scrollTo(0,0);
      }
    };

    return {
        fnScrollToTop
    }
}