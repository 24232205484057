import React from "react";
import NoImage from "../../../assets/Images/noImage.jpg"
function Image({ src, alt, className, hight, width, style }) {
  return (
    <img
      src={src}
      alt={alt}
      onError={(e) => (e.target.src = NoImage)}
      className={className}
      height={hight}
      width={width}
      style={style}
    />
  );
}

export default Image;
