import Image from "../image/Image.js";
import { Link } from "react-router-dom";
import React, {useEffect, useState} from "react";
import PhoneOutline from "../../../assets/Icons/phone__outline.jsx";
import MailOutline from "../../../assets/Icons/mail__outline.jsx";
import LocationOutline from "../../../assets/Icons/location__outline.jsx";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import RoutesLink from "../../../constant/RoutesLink.js";
import InquiryModel from "../inquiryModel/InquiryModel.jsx"
import useScrollToTop from "../../../hooks/useScrollToTop.js";
const Footer = () => {

    const { t } = useTranslation();
    const { fnScrollToTop } = useScrollToTop({ parentClassName : 'routes-container', onClickClassName : 'small__btn' });

    const [inquiryModel, setInquiryModel] = useState({ first: false, second: false });
    const handleInquiryModel = () => {
        setInquiryModel({ first: true, second: false });
    };

    return (
        <div className="footer__section section routes-container">
            <InquiryModel inquiryModel={inquiryModel} setInquiryModel={setInquiryModel} />

            <div className="footer__header">
                <Image src="/images/footer__logo.png" width={120} height={162} alt="" />
                <p className="poppins__18">
                    {t("footer.heading")}
                </p>
            </div>
            <div className="footer__links">
                <div  onClick={handleInquiryModel} className='small__btn  cursor-pointer'>
                    {t("footer.link1")}
                </div> 
                <Link to={RoutesLink.REFERENCER} onClick={fnScrollToTop} className='small__btn'>
                    {t("footer.link2")}
                </Link>
                <Link to={RoutesLink.ABOUT} onClick={fnScrollToTop} className='small__btn'>
                    {t("footer.link3")}
                </Link>
                <Link to={RoutesLink.PRIVACY_POLICY} onClick={fnScrollToTop} className='small__btn'>
                    {t("footer.link4")}
                </Link>
                <Link to={RoutesLink.TRADES_CONDITIONS} onClick={fnScrollToTop} className='small__btn'>
                    {t("footer.link5")}
                </Link>
            </div>
            <div className="footer__contacts">
                <div className="contact__div border__right" style={{ display: 'flex' }}>
                    <div>
                        <PhoneOutline />
                    </div>
                    <a href={`mailto:${t("company.phone")}`} className="poppins__18">
                        {t("company.phone")}
                    </a>
                </div>
                <div className="contact__div border__right" style={{ display: 'flex' }}>
                    <div>
                        <MailOutline />
                    </div>
                    <a href={`mailto:${t("company.email")}`} className="poppins__18">
                        {t("company.email")}
                    </a>
                </div>
                <div className="contact__div" style={{ display: 'flex' }}>
                    <div>
                        <LocationOutline iconColor="#333" />
                    </div>
                    <a href={`https://www.google.com/maps/place/${t("company.address")}`} target="_blank" rel="noopener noreferrer"  className="poppins__18">
                        {t("company.address")}
                    </a>
                </div>
            </div>
            <div className="footer__links copy__right">
                <Link to="/" className='poppins__18'>
                    © Yak Sport
                </Link>
                <Link to="/" className='poppins__18'>
                    CVR: 38 71 56 90
                </Link>
            </div>
            <div className="footer__links copy__right">
                <Link to="/" className='poppins__18'>
                Medlem af Rejsegarantifonden nr. 2848
                </Link>
                
            </div>
            <div className="social__links">
                <Link to="https://www.facebook.com/yaksportdk/" target="_blank" rel="noopener noreferrer">
                    <span className="back__icon">
                        <i className="ri-facebook-fill" ></i>
                    </span>
                </Link>
                <Link to="https://www.instagram.com/yaksportdk/" target="_blank" rel="noopener noreferrer">
                    <span className="back__icon">
                        <i className="ri-instagram-fill"></i>
                    </span>
                </Link>
                <Link to="https://www.youtube.com/channel/UCpui6kUydOTm-Tj-cq0CRJQ" target="_blank" rel="noopener noreferrer">
                    <span className="back__icon">
                        <i className="ri-youtube-fill"></i>
                    </span>
                </Link>
                <Link to="https://www.tiktok.com/@yaksport" target="_blank" rel="noopener noreferrer">
                    <span className="back__icon">
                        <i className="ri-tiktok-fill"></i>
                    </span>
                </Link>
            </div>
        </div>
    )
}
export default Footer;