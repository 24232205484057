import React, { useEffect, useRef, useState } from 'react'
import { useGetRegionDataQuery } from '../../redux/storeApis';
import { getLocalStorage, setLocalStorage } from '../shared/local_storage';
import { KEYS } from '../../constant/Index';
import useOutsideClick from '../../hooks/useOutsideClick';

const RegionIcon = () => {

    const { data: regions } = useGetRegionDataQuery();

    const selectedRegion = getLocalStorage(KEYS.region_lang);

    const regionModalRef = useRef(null);
    const [showRegionModal, setShowRegionModal] = useState(false);
    const [regionsData, setRegionsData] = useState([]);

    useEffect(() => { setRegionsData(regions?.data?.regions); }, [regions]);

    useOutsideClick(regionModalRef, () => setShowRegionModal(false));

    const fnOnClickRegionIcon = (lang) => {
        setLocalStorage(KEYS.region_lang, lang?.name);
        window.location.reload();
    };

  return (
    <div className="d__flex flex__col" style={{ position: "relative" }}>

        <i title="Select region" onClick={()=> setShowRegionModal(true)} className="bx bx-world fs__24 solid__black__60 cursor-pointer"></i>

        { showRegionModal && <div
          ref={regionModalRef}
          className="d__flex flex__col gap__8"
          style={{
            position: "absolute",
            top: "110%",
            background: "#FFF",
            zIndex: "1",
            padding: "16px",
            borderRadius: "10px",
            boxShadow: "0 4px 20px 0px rgba(0, 0, 0, .08)",
            width: "200px",
          }}
        >
            {regionsData?.map((lang) => {
                return (
                    <button
                        style={{ 
                            color: selectedRegion == lang?.name ? "#333" : "#4A6DA7",
                            width: "100%",
                            textAlign: "start",
                            userSelect: "none",
                        }}
                        onClick={()=> fnOnClickRegionIcon(lang)}
                    >
                        {lang?.title}
                    </button>
                );
            })}

        </div>}
    </div>
  )
}

export default RegionIcon