import React from 'react'

const user__blue = ({className, iconColor='#4A6DA7'}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 26 30" fill="none" className={className}>
            <path d="M5.7701 19.6421C3.88374 20.7653 -1.06218 23.0588 1.95022 25.9287C3.42175 27.3307 5.06065 28.3333 7.12116 28.3333H18.8788C20.9393 28.3333 22.5783 27.3307 24.0498 25.9287C27.0622 23.0588 22.1163 20.7653 20.2299 19.6421C15.8064 17.0082 10.1936 17.0082 5.7701 19.6421Z" stroke={iconColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19 7.66666C19 10.9804 16.3137 13.6667 13 13.6667C9.68629 13.6667 7 10.9804 7 7.66666C7 4.35295 9.68629 1.66666 13 1.66666C16.3137 1.66666 19 4.35295 19 7.66666Z" stroke={iconColor} stroke-width="2" />
        </svg>
    )
}

export default user__blue