import React from 'react'

const MenuOutline = ({onClick}) => {
    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
            <path d="M17 8L7 8" stroke="#656565" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17 1L1 1" stroke="#656565" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17 15L1 15" stroke="#656565" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default MenuOutline