import React, { useEffect, useRef } from "react";
import useSearchFilter from "../../../../../../hooks/searchFilter/useSearchFilter";
import DZText from "../../../../../text/DZText";
import { useSelector } from "react-redux";
import { KEYS } from "../../../../../../constant/Index";
import { getLocalStorage } from "../../../../../shared/local_storage";
import useHomePageDataManager from "../../../../../../hooks/useHomePageDataManager";
const FlightPopup = ({
  activeFilter,
  // airportData,
  searchFields,
  setSearchFields,
  setActiveFilter,
  inputFiled,
  setInquiryFirstData,
  handleClosePopup,
}) => {
  const { airportRegions, regionAirports } = useHomePageDataManager();

  const selectedLanguage = getLocalStorage(KEYS.lang);
  const handleFlight = (value, name) => {
    if (inputFiled) {
      setInquiryFirstData((prevDate) => ({
        ...prevDate,
        flightId: value,
        flight: name,
      }));
      handleClosePopup();
    } else {
      setSearchFields({
        ...searchFields,
        flight: name,
        flightId: value,
      });
      setActiveFilter("");
    }
  };

  const airportData = regionAirports?.filter((airport) =>
    airportRegions?.some((region) => region?.airport_id == airport?.id)
  );


  if (!airportData) return null;
  return (
    <div
      className={`search__popup min__w__200
      ${inputFiled ? "t__70" : ""}
      ${
        activeFilter == "flight" || inputFiled == "flight"
          ? "active__popup"
          : ""
      }`}
    >
      {Object?.keys(airportData)?.map((key) => {
        return (
          <DZText
            key={key}
            className="poppins__18 cursor__p"
            onClick={() =>
              handleFlight(airportData[key]?.id, airportData[key]?.name)
            }
          >
            {airportData[key]?.name}
          </DZText>
        );
      })}
    </div>
  );
};

export default FlightPopup;
