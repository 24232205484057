import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './cookies-banner.css';
import { Config } from "../../../constant/Index";

const CookiesConsentBanner = () => {
    const [showBanner, setShowBanner] = useState(true);
    const [showDetails, setShowDetails] = useState(false);

    const [cookiePreferences, setCookiePreferences] = useState({
        essential: true,
        functional: true,
        analytical: false,
        marketing: false,
    });

    const handleCookiesConsent = (isAllAccepted) => {

        if (isAllAccepted) {
            Cookies.set(Config.__COOKIE__CONSENT__, JSON.stringify({
                essential: true,
                functional: true,
                analytical: true,
                marketing: true,
            }), { expires: 365 });
        } else {
            Cookies.set(Config.__COOKIE__CONSENT__, JSON.stringify(cookiePreferences), { expires: 365 }); // cookie will expire in 365 days
        }

        setShowBanner(false);
        document.getElementById('root').style.filter = 'none';
        document.getElementById('root').style.pointerEvents = 'all';
    };

    const handlePreferenceChange = (preference) => {
        setCookiePreferences(prevState => ({
            ...prevState,
            [preference]: !prevState[preference],
        }));

    };

    return (
        <>
            <style>{`
                #root {
                    filter: blur(3px);
                    pointer-events: none;
                }
            `}</style>

            {showBanner && (
                <div className="fixed inset-0 bg-[#00000050] flex items-center justify-center z-[999] overflow-hidden">
                    <div className="bg-[#F6F8FB] flex flex-col gap-[24px] rounded-[20px] max-w-[600px] w-full max-sm:m-[24px] sm:p-[32px] p-[24px] shadow-xl shadow-[#00000010] max-h-[90dvh] overflow-y-scroll ">
                        <img src="/images/nav__logo.png" className="max-w-[140px]" alt="" />
                        <div className="cookie-image flex flex-col items-center gap-[24px] rounded-[20px] bg-[#F6F8FB]">
                            <div className="flex flex-col gap-[6px]">
                                <span className="text-[28px] font-medium text-[#4A6DA7]">Cookie-indstillinger</span>
                                <p className="text-[#666]">Vores hjemmeside gemmer fire typer cookies. Du kan til enhver tid vælge, hvilke cookies du accepterer, og hvilke du nægter. Du kan læse mere om, hvad cookies er, og hvilke typer cookies vi gemmer i vores</p>
                            </div>
                            <div className="sm:flex grid grid-cols-2 items-center gap-[24px]">
                                <div className="switch-container">
                                    <span className="switch-name text-[15px] text-[#666666]">Essentielle</span>
                                    <div className="switch active disabled">
                                        <div className="switch-knob"></div>
                                    </div>
                                </div>
                                <div className="switch-container">
                                    <span className="switch-name text-[15px] text-[#666666]">Funktionelle</span>
                                    <div className="switch active disabled">
                                        <div className="switch-knob"></div>
                                    </div>
                                </div>
                                <div className="switch-container" onClick={() => handlePreferenceChange('analytical')}>
                                    <span className="switch-name text-[15px] text-[#666666]">Analytiske</span>
                                    <div className={`switch ${cookiePreferences.analytical ? 'active' : ''}`}>
                                        <div className="switch-knob"></div>
                                    </div>
                                </div>
                                <div className="switch-container" onClick={() => handlePreferenceChange('marketing')}>
                                    <span className="switch-name text-[15px] text-[#666666]">Marketing</span>
                                    <div className={`switch ${cookiePreferences.marketing ? 'active' : ''}`}>
                                        <div className="switch-knob"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-[12px] max-sm:flex-col justify-center w-full">
                                <button onClick={() => handleCookiesConsent()} className="rounded-[8px] bg-[#CEDBF0] px-[16px] py-[8px] text-[#4A6DA7]">Accepter valgt</button>
                                <button onClick={() => handleCookiesConsent(true)} className="rounded-[8px] bg-[#4A6DA7] px-[16px] py-[8px] text-white">Accepter alle</button>
                            </div>
                            <div className="flex justify-center w-full">
                                <button className="link-button bg-none border-none text-[#4A6DA7] underline cursor-pointer" onClick={() => setShowDetails(!showDetails)}>
                                    {showDetails ? 'Skjul detaljer' : 'Vis detaljer'}
                                </button>
                            </div>
                            {showDetails && (
                                <div className="flex flex-col gap-[24px]">
                                    <div className="flex flex-col gap-[12px]">
                                        <div className="flex items-center gap-[4px]">
                                            <span className="text-[#4A6DA7]">Essentielle</span>
                                        </div>
                                        <p className="text-[14px] text-[#666]">er nødvendige af tekniske årsager. Uden dem fungerer denne hjemmeside muligvis ikke korrekt.</p>
                                    </div>
                                    <div className="flex flex-col gap-[12px]">
                                        <div className="flex items-center gap-[4px]">
                                            <span className="text-[#4A6DA7]">Funktionelle</span>
                                        </div>
                                        <p className="text-[14px] text-[#666]">er nødvendige for specifik funktionalitet på hjemmesiden. Uden dem kan nogle funktioner være deaktiveret.</p>
                                    </div>
                                    <div className="flex flex-col gap-[12px]">
                                        <div className="flex items-center gap-[4px]">
                                            <span className="text-[#4A6DA7]">Analytiske</span>
                                        </div>
                                        <p className="text-[14px] text-[#666]">tillade os at analysere brugen af ​​hjemmesiden og forbedre den besøgendes oplevelse.</p>
                                    </div>
                                    <div className="flex flex-col gap-[12px]">
                                        <div className="flex items-center gap-[4px]">
                                            <span className="text-[#4A6DA7]">Marketing</span>
                                        </div>
                                        <p className="text-[14px] text-[#666]">tillade os at personliggøre din oplevelse og sende dig relevant indhold og tilbud på denne hjemmeside og andre hjemmesider.</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* {showBanner && (

                <div className="cookies-overlay">
                    <div className="cookies-banner">
                        <p>This website uses cookies to enhance user experience. Please select your preferences:</p>
                        <div className="cookie-preferences">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={cookiePreferences.essential}
                                    onChange={() => handlePreferenceChange('essential')}
                                    disabled
                                />
                                <span className="cookie-label">Essential Cookies</span> <></>
                                <span className="cookie-description">{'er nødvendige af tekniske årsager. Uden dem fungerer denne hjemmeside muligvis ikke korrekt.'}</span>
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={cookiePreferences.functional}
                                    onChange={() => handlePreferenceChange('functional')}
                                    disabled
                                />
                                <span className="cookie-label">Functional Cookies</span> <></>
                                <span className="cookie-description">{'er nødvendige for specifik funktionalitet på hjemmesiden. Uden dem kan nogle funktioner være deaktiveret.'}</span>
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={cookiePreferences.analytical}
                                    onChange={() => handlePreferenceChange('analytical')}
                                />
                                <span className="cookie-label">Analytical Cookies</span> <></>
                                <span className="cookie-description">{'tillade os at analysere brugen af ​​hjemmesiden og forbedre den besøgendes oplevelse.'}</span>
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={cookiePreferences.marketing}
                                    onChange={() => handlePreferenceChange('marketing')}
                                />
                                <span className="cookie-label">Marketing Cookies</span> <></>
                                <span className="cookie-description">{'tillade os at personliggøre din oplevelse og sende dig relevant indhold og tilbud på denne hjemmeside og andre hjemmesider.'}</span>
                            </label>
                        </div>
                        <button onClick={() => handleCookiesConsent(true)}>Accept All</button> <></>
                        <button onClick={() => handleCookiesConsent(false)}>Accept Preferences</button>
                    </div>
                </div>
            )} */}


        </>
    );
};

export default CookiesConsentBanner;
