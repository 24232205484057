import React from 'react'

const CalenderBlack = ({className, iconColor="#4A6DA7"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" className={className}>
            <path d="M17 1V3M5 1V3" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.9955 12H11.0045M10.9955 16H11.0045M14.991 12H15M7 12H7.00897M7 16H7.00897" stroke={iconColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.5 7H19.5" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.5 11.2432C1.5 6.88594 1.5 4.70728 2.75212 3.35364C4.00424 2 6.01949 2 10.05 2H11.95C15.9805 2 17.9958 2 19.2479 3.35364C20.5 4.70728 20.5 6.88594 20.5 11.2432V11.7568C20.5 16.1141 20.5 18.2927 19.2479 19.6464C17.9958 21 15.9805 21 11.95 21H10.05C6.01949 21 4.00424 21 2.75212 19.6464C1.5 18.2927 1.5 16.1141 1.5 11.7568V11.2432Z" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 7H20" stroke={iconColor} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default CalenderBlack